import { render, staticRenderFns } from "./ListCardRowSelectionResult.vue?vue&type=template&id=24288be4&scoped=true"
import script from "./ListCardRowSelectionResult.vue?vue&type=script&lang=js"
export * from "./ListCardRowSelectionResult.vue?vue&type=script&lang=js"
import style0 from "./ListCardRowSelectionResult.vue?vue&type=style&index=0&id=24288be4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24288be4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VCardText,VDivider,VImg})
